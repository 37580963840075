module.exports = [{
      plugin: require('/Users/CALI/Sites/fiorellapattoni/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/CALI/Sites/fiorellapattoni/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/CALI/Sites/fiorellapattoni/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/Users/CALI/Sites/fiorellapattoni/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-63824008-1","head":false},
    },{
      plugin: require('/Users/CALI/Sites/fiorellapattoni/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
