import React from 'react';
import { Link } from 'gatsby';
import MainNav from './mainNav'
import logo from "../images/logo.svg"
import '../sass/theme/header.scss'

const Header = ({ siteTitle }) => (
  <header className='header-main'>
  	<figure className='logo'>
  		<Link to='/'>
  			<img src={logo} alt={siteTitle} />
  		</Link>
  	</figure>
  	<MainNav />
  </header>
);

export default Header;
