// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-collection-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-piece-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/templates/piece.js" /* webpackChunkName: "component---src-templates-piece-js" */),
  "component---src-pages-404-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-care-instructions-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/care-instructions.js" /* webpackChunkName: "component---src-pages-care-instructions-js" */),
  "component---src-pages-contact-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-order-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/custom-order.js" /* webpackChunkName: "component---src-pages-custom-order-js" */),
  "component---src-pages-index-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wholesale-js": () => import("/Users/CALI/Sites/fiorellapattoni/src/pages/wholesale.js" /* webpackChunkName: "component---src-pages-wholesale-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/CALI/Sites/fiorellapattoni/.cache/data.json")

