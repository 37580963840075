import { FaBars, FaWindowClose } from "react-icons/fa";

import React, { Component } from "react";
import { Link } from "gatsby";

import "../sass/navigation/mainNav.scss";

export default class MainNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toogle: false
		};
		this.toggleClass = this.toggleClass.bind(this);
	}
	toggleClass() {
		const currentState = this.state.toogle;
		this.setState({ toogle: !currentState });
	}
	render() {
		return (
			<nav className="main-nav">
				<button onClick={() => this.toggleClass()} className="toogle-menu">
					{!this.state.toogle ? <FaBars /> : <FaWindowClose />}
				</button>
				<ul className={this.state.toogle ? "show" : null}>
					<li>
						<Link to="/" activeClassName="active">
							Home
						</Link>
					</li>
					<li>
						<Link to="about-me" activeClassName="active">
							About Me
						</Link>
					</li>
					<li>
						<Link to="custom-order" activeClassName="active">
							Custom Orders
						</Link>
					</li>
					<li>
						<Link to="wholesale" activeClassName="active">
							Wholesale information
						</Link>
					</li>
					<li>
						<Link to="contact" activeClassName="active">
							Contact
						</Link>
					</li>
				</ul>
			</nav>
		);
	}
}
