import React from "react";
import "../sass/theme/footer.scss";
import { Link } from "gatsby";

const Footer = () => (
	<footer className="footer-main">
		<div>Fiorella Pattoni - Handmade wearable art.</div>
		<div>
			<Link to="/care-instructions">Care instructions</Link>
		</div>
	</footer>
);

export default Footer;
