import React from 'react'
import Header from '../components/header.js'
import Footer from '../components/footer.js'
import '../sass/index.scss'

const TemplateWrapper = ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
)

export default TemplateWrapper
